// 开发环境域名
//https://php-b2csaas.yixiangonline.com
const host_development = 'https://saaslikeshop.qcsheji.cn'

export default {
    // 版本
    version: '3.1.0',
    baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
    tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
